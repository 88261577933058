#services{
    background-color: #06283D;
    min-height: 100vh;
}

.service-image {
    width: 100%;
    object-fit: cover;
    height: 350px;
    padding: 0;
    margin: 0;
}