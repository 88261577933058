
.navbar {
    /* background-color: #06283D; */
    color: red;
    font-weight: bolder;
    background-color: none;
    
}

.flag-image {
    height: 25px;
    width: 25px;
    padding-right: 2px;
}

#basic-nav-dropdown{
    color: white;
}

#navlinks {
    color: white;
}

@media (max-width : 768px) {
.navbar-collapse {
    background-color: none;
    position:absolute;
    margin-top: 350px;
    padding-left: 2rem;
    padding-top: 1rem;
    width: 50%;
    background-color: aliceblue;
    border-radius: 10px;
    z-index: 300;
  }
  .navbar-light button.navbar-toggler {
    border-color: red;
    color: red;
  }
  .navbar{
    color: aliceblue;
  }
  #basic-nav-dropdown{
    color: red;
}

#navlinks {
    color: red;
}
}