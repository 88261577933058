/*process-box*/

.process-box{
    background: #fff;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    box-shadow: 2px 2px 7px 0 #00000057;
    margin-bottom: 50px;
    z-index: 10;
}

.process-step{
    background: #dc3545 !important;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    color: #fff;
    height: 100%;
    padding-top: 8px;
    position: relative;
    top: -26px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: -6px 8px 0px 0px #00000014;
}
.process-last:before{
    display: none;
}
.process-box p{
    z-index: 9;
}
.process-step p{
    font-size: 20px;
}
.process-step h2{
    font-size: 39px;
}
.process-step:after{
    content: "";
    border-top: 8px solid #04889800;
    border-bottom: 8px solid #048898;
    border-left: 8px solid #04889800;
    border-right: 8px solid #048898;
    display: inline-grid;
    position: absolute;
    left: -16px;
    top: 0;
}
.process-step:before{
    content: "";
    border-top: 8px solid #ff000000;
    border-bottom: 8px solid #048898;
    border-left: 8px solid #048898;
    border-right: 8px solid #ff000000;
    display: inline-grid;
    position: absolute;
    right: -16px;
    top: 0;
}
.arrow-right1{
    height: 200px;
    width: 200px;
    position: absolute;
    right: -15px;
    margin-top: 50px;
    z-index: -10;
}

.arrow-left{
    height: 200px;
    width: 200px;
    margin-right: -10px;
    position: absolute;
    left: -15px;
    margin-top: 10px;
    z-index: -10;
    
}
.agency-image {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 25px;
    z-index: 100;
}

@media (max-width: 900.98px) {

    .arrow-left{
      width: 200px;
      height: 200px;
      margin-top: 20px;
      left: -45px;
      margin-top: 50px;
      z-index: -10;
    }
    .arrow-right{
        width: 200px;
        height: 200px;
        right: -45px;
        margin-top: 50px;
        z-index: -10;
    }


}

@media (max-width: 767.98px) {

    .process-box {
        text-align: center;
    }

    .arrow-left{
      width: 0;
      height: 0;
      margin-top: 20px;
    }
    .arrow-right{
        width:0 ;
        height: 0;
        margin-top: 20px;
    }
    .agency-image{
        height: 0;
        width: 0;
    }
    
}



