#analytics{
  background-color: #06283D;
}

.carousel-image {
    height: 500px;
    width: 100%;
    object-fit:cover;
    ;
}

h3 {
    color: black;
}

.card-image{
    height: 100px;
    width: 100px;
    object-fit: cover;
    align-self: center;
    border-radius: 50%;
    margin-top: -4rem;
    
}