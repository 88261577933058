.fill{
    min-height: 100vh;
    /* background: url('./Assets/agency.jpg') no-repeat center center fixed;  */
    background-position:center; 
    background-attachment:scroll; 
    -o-background-size:cover; 
    -moz-background-size:cover; 
    -webkit-background-size:cover; 
    background-size:cover; 
    background-repeat:no-repeat;
}

.hero{
    min-height: 100vh;
    align-items: center;
}

.hero-image{
    width: 100%;
    height: 100%;
    object-fit:contain;
    padding: 0;
    margin: 0;
    border-radius: 25px;
}

@media (max-width: 1024px) {

    .fill{
        text-align: center;
        height: auto;
    }
    #hero-section{
       margin-top: -100px;
    }
    .hero{
        max-height:fit-content;
    }
    .hero-image {
        height: auto;
        padding: 0;
    }
    

}

@media (max-width: 767.98px) {

    .fill{
        text-align: center;
        height: auto;
    }
    #hero-section{
       margin-top: -150px;
    }
    .hero{
        max-height:fit-content;
    }
    .hero-text{
        padding-top: 100px;
    }
    .hero-image {
        height: auto;
        padding: 0;
    }

}

