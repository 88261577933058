#contact{
    padding-bottom: 4rem;
}

.form{
    width: 75% ;
}

@media (max-width: 767.98px) {
  
    .form{
        width: auto;
    }

}


