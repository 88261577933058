/* components/Ticker.css */
.ticker-container {
  background-color: #dc3545; /* Bootstrap danger color */
  color: white;
  padding: 10px 0;
  overflow: hidden; /* Prevents overflow */
  white-space: nowrap; /* Prevents wrapping */
  position: fixed; /* Fixed position to stay at the top */
  top: 0; /* Align it to the top */
  left: 0; /* Align it to the left */
  right: 0; /* Stretch it to the right */
  z-index: 1000; /* Ensure it stays above other elements */
  height: 40px; /* Ensure a height for visibility */
}

  .ticker {
    display: inline-block;
    animation: ticker-scroll 35s linear infinite; /* Adjust duration as needed */
  }
  
  .ticker-text {
    font-weight: bold;
    display: inline-block; /* Ensure it's inline for scrolling */
  }
  
  @keyframes ticker-scroll {
    0% {
      transform: translateX(100%); /* Start off the right side */
    }
    100% {
      transform: translateX(-100%); /* End off the left side */
    }
  }
  