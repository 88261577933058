.card-header{
    /* background-color: #06283D; */
    background-image: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
    color: white;
    margin-left: 0;
}

.popular-card {
    border: 2px solid #ff6b6b; /* Stronger border to highlight the popular card */
    box-shadow: 0 8px 16px rgba(255, 107, 107, 0.2); /* Extra shadow */
    transform: scale(1.05); /* Slightly scale the popular card */
    transition: all 0.3s ease-in-out; /* Smooth transition effect */
  }
  
  .popular-card:hover {
    transform: scale(1.08); /* Increase scaling on hover */
  }
  
  .badge-popular {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff6b6b;
    color: white;
    padding: 10px;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 700;
  }